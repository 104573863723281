<template>
  <course-detail-component />
</template>

<script>
  import CourseDetailComponent from '../components/CourseDetailComponent'

  export default {
    name: 'CourseDetail',
    components: {
      CourseDetailComponent
    },
    created () {
      this.$store.commit('increment')
      console.log("log from Course detail:",this.$store.state.count)
    }
  }
</script>
